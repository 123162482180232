<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
            <h4 class="text-center pa-10 text overline text-h4">{{ $t('app.notFoundStart') }}</h4>
            <v-progress-linear indeterminate color="light-blue darken-2"></v-progress-linear>
            <h4 class="text-center pa-10 text overline text-h4">{{ $t('app.notFoundEnd') }}</h4>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'notFound',
};
</script>
